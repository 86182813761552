<template>
<v-container>
  <!-- BOC:[breadcrumbs] -->
  <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
  <!-- EOC -->
  <!-- BOC:[form] -->
  <PageChangePassword
    :role="role"
    action="add"
    :callbackSuccess="callbackSuccess"
  ></PageChangePassword>
  <!-- EOC -->
</v-container>
</template>

<script>

  import PageChangePassword from '@/components/Bread/Password/PageChangePassword'
  import { mapState } from 'vuex'
  export default {
    components:{
      PageChangePassword,
    },
    computed: mapState({
      school: state => state.school.data,
    }),
    props:[
      //
    ],
    data: () => ({
      //BOC:[breadcrumbs]
      breadcrumbs: [],
      //EOC
      //BOC:[role]
      role:'Moderator',
      //EOC
    }),
    created() {
      //BOC:[breadcrumbs]
      this.breadcrumbs.push({
        text:this.$t("model.name.dashboard"),
        to:{name:'Page'+this.role+'Dashboard'},
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:this.$t("model.name.account"),
        to:{name:'PageModeratorAccountBrowse'},
        exact:true,
      })
      this.breadcrumbs.push({
        text:this.$t("view.PageBackendPasswordChange.password_change_title"),
        to:{name:'PageModeratorPasswordChange'},
        exact:true,
      })
      //
      //EOC
    },
    mounted() {
      //
    },
    methods: {
      callbackSuccess() {
         this.$router.push({name:'PageModeratorAccountBrowse'})
      },
    }
  }
</script>