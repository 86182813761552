<template>
  <v-text-field
    class="input-group--focused"
    @click:append="show3 = !show3"
    v-model="inputVal"
    :type="show3 ? 'text' : 'password'"
    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
    :label="field.name"
    :rules="rules[field.key]"
    :value="value ? value : ''"
  ></v-text-field>
</template>

<script>
import i18n from "@/plugins/i18n";
export default {
  props: ["callbackInput", "field", "value"],
  data: () => ({
    show3: false,
    rules: {
      password: [(v) => !!v || i18n.t("rules.new_password")],
      oldPassword: [(v) => !!v || i18n.t("rules.old_password")],
      confirmPassword: [(v) => !!v || i18n.t("rules.confirm_password")],
    },
  }),
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {},
};
</script>

<style>
</style>